import { ReactNode } from 'react'
import { BestSupportBadges } from './Badges/Badges'
import { BestSupportReviews } from './Reviews/Reviews'
import clsx from 'clsx'
import classes from './BestSupport.module.scss'

interface BestSupportProps {
  isSolutionsSection?: boolean
  hasLinks?: boolean
  subtitle?: ReactNode
  className?: string
}

export const BestSupport: React.FC<BestSupportProps> = ({
  isSolutionsSection,
  hasLinks = true,
  subtitle,
  className,
}) => {
  return (
    <section className={className}>
      <div
        className={clsx(
          classes.wrapper,
          isSolutionsSection && classes.wrapper__insetMedium,
          !hasLinks && classes.wrapper__insetLarge,
        )}
      >
        <BestSupportBadges className={classes.badges} subtitle={subtitle} />
        <BestSupportReviews hasLinks={hasLinks} />
      </div>
    </section>
  )
}
