import { HTMLAttributes, PropsWithChildren } from 'react'
import clsx from 'clsx'
import classnames from './Blocks.module.css'

type BlockTag = 'section' | 'div' | 'article'
type Variant =
  | 'gradient'
  | 'gradientMob'
  | 'solid'
  | 'solidMob'
  | 'shadow'
  | 'shadowMob'
  | 'shadowTab'
  | 'default'
type Padding = 'base' | 'baseMob' | 'baseTab' | 'content'
type Radius = 'sm' | 'md' | 'lg'
type Divider = 'mobile' | 'full'

interface BlockProps extends HTMLAttributes<HTMLDivElement & HTMLElement> {
  as?: BlockTag
  variant?: Variant
  r?: Radius
  pad?: Padding
  divider?: Divider
}

const blockVariants: Record<Variant, string> = {
  gradient: classnames.block_gradient, // https://integration.app/ecosystem-partnership, section: What is required from you
  gradientMob: classnames.block_gradientMob,
  solid: classnames.block_solid,
  solidMob: classnames.block_solidMob,
  shadow: classnames.block_shadow,
  shadowMob: classnames.block_shadowMob,
  shadowTab: classnames.block_shadowTab,
  default: classnames.block_default,
}

const paddingStyles: Record<Padding, string> = {
  base: classnames.padding_base,
  baseMob: classnames.padding_baseMob,
  baseTab: classnames.padding_baseTab,
  content: classnames.padding_content,
}

const radiusStyles: Record<Radius, string> = {
  sm: classnames.radius_sm,
  md: classnames.radius_md,
  lg: classnames.radius_lg,
}

const dividerType: Record<Divider, string> = {
  mobile: classnames.divider_mob,
  full: classnames.divider_full,
}

const Block = ({
  as: Tag = 'div',
  variant = 'default',
  r = 'lg',
  pad,
  divider,
  children,
  className,
  ...props
}: PropsWithChildren<BlockProps>) => {
  return (
    <Tag
      className={clsx(
        blockVariants[variant],
        radiusStyles[r],
        pad && paddingStyles[pad],
        divider && dividerType[divider],
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  )
}

export { Block }
