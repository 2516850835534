import { TbArrowUpRight } from 'react-icons/tb'
import { Heading, Text } from 'ui-kit/typography'
import { Link } from 'routes/components/Shared'
import { G2_INTEGRATION_APP_URL } from 'routes/constants'
import { ReviewsDataType } from './reviewsData'
import clsx from 'clsx'
import classes from './Reviews.module.scss'

export const ReviewsCard = ({
  card,
  hasLinks,
}: {
  card: ReviewsDataType
  hasLinks?: boolean
}) => {
  const { name, photo, position, company, content, integrationLink, g2Link } =
    card

  const reviewLink = g2Link
    ? `${G2_INTEGRATION_APP_URL}${g2Link}`
    : integrationLink

  return (
    <div className={clsx('flex-column', classes.card)}>
      <div className={classes.card_top}>
        <Text
          size={'lg'}
          lh={'tight'}
          center
          balance
          className={classes.card_review}
        >
          {content}
        </Text>
      </div>

      <div className={clsx('flex-column', classes.card_bottom)}>
        <div className={clsx('flex-column', classes.card_author)}>
          <div className={classes.card_wrapper}>
            <div className={clsx('page__glow', classes.card_glow)} />
            <div className={classes.card_visual}>
              <div className={classes.card_image}>
                <img
                  src={photo.src}
                  loading='lazy'
                  width={56}
                  height={56}
                  alt={name}
                />
              </div>
            </div>
          </div>
          <div className={classes.card_info}>
            <Heading
              as={'h4'}
              size={'xs'}
              lh={'loose'}
              className={classes.card_name}
            >
              {name}
            </Heading>
            {position && (
              <Text as={'span'} size={'sm'} className={classes.card_caption}>
                {position} at {company}
              </Text>
            )}
          </div>
        </div>

        {hasLinks && (
          <div className={classes.card_actions}>
            <Link
              className={clsx('page__button', classes.card_link)}
              type='secondary'
              target='_blank'
              href={reviewLink}
            >
              Read public review
              <TbArrowUpRight className={classes.card_icon} />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
